<template>
  <div class="public-container">
    <el-container>
      <el-header height="50px">
        <i
          v-if="$route.path != '/marketing/preview'"
          class="el-icon-arrow-left"
          @click="onBack"
        ></i>

        <el-menu
          v-show="listName == 'marketing'"
          default-active="1"
          mode="horizontal"
          active-text-color="#20a0ff"
        >
          <el-menu-item index="1">{{ title }}</el-menu-item>

          <div class="market-state" v-if="listName != ''">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="marketingFeature"
              @change="initMarketingFeature('change')"
            >
            </el-switch>
            <span style="margin-left: 10px;"
              >{{ marketingFeature ? "已" : "未" }}开启营销</span
            >
          </div>
        </el-menu>
        <MenuSecond v-show="listName" :listName="listName" :checkPath="checkPath"></MenuSecond>
      </el-header>
      <!--  -->
      <el-main>
        <router-view />

        <!-- <div class="preview">
          <div
            class="df-row"
            v-for="(pathItem, pathIndex) in pathList"
            :key="pathIndex"
          >
            <div
              class="df-col"
              v-for="(chlidItem, chlidIndex) in pathItem"
              :key="chlidIndex"
              @click="goto(chlidItem.path)"
            >
              <div class="dfWebIcon" :class="chlidItem.class"></div>

              <div class="describe">
                <span class="title">{{ chlidItem.title }}</span>
                <span class="note">{{ chlidItem.note }}</span>
              </div>
            </div>
          </div>
        </div> -->
      </el-main>
    </el-container>
  </div>
</template>

<script>
import MenuSecond from "../../components/menu/menu-second.vue";
export default {
  name: "StoreWebIndex",

  components: { MenuSecond },

  watch: {
    '$route': {
      handler: function (newVal) {
        let { meta, query } = newVal,
          listName = query.list_name || "",
          { title } = meta;
        this.listName = listName;
        this.title = title;
        this.checkPath = listName != 'marketing' ? '/marketing' : '/'
      },
      deep: true,
      immediate: true
    },
  },

  data() {
    return {
      listName: "",
      checkPath: "",
      title: "营销",
      marketingFeature: false,
    };
  },

  mounted() {
    this.initMarketingFeature()
  },

  methods: {
    initMarketingFeature(type = "init") {
      let systemData = JSON.parse(window.sessionStorage.getItem("systemData"));
      this.marketingFeature = systemData.feature.MarketingFeature;
      if (type == "change" && !this.marketingFeature) {
        this.$alert(
          "营销功能暂不支持用户手动开启，请联系帝峰客服人员",
          "提示",
          {
            confirmButtonText: "确定",
          }
        );
      }
    },

    onBack() {
      this.$router.push("/marketing/preview");
    },
  },
};
</script>

<style lang="less" scoped>
.el-header {
  .flex-row;
  padding: 0;
  border-bottom: 1px solid #e5e9f2;
  background-color: #ffffff;

  > i {
    color: #aaa;
    font-size: 20px;
    font-weight: bolder;
    margin-left: 10px;
    padding: 5px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      color: #888;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .el-menu {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 30px;

    .el-menu-item {
      position: relative;
      height: 50px;
      line-height: 50px;
      padding: 0 5px;
      margin-right: 20px;
    }

    .market-state {
      .flex-row;
      position: absolute;
      right: 0;
      justify-content: end;
      align-items: center;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}

.el-main {
  height: calc(100vh - 60px);
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0 20px;
  background-color: #ffffff;
}
</style>
